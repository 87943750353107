import $ from "jquery";

let infoBlock = $('.main-marketing__list');
// if(infoBlock.length < 1) return;

let $time               = $('#jsTargetTime'),
    $location           = $('#jsTargetLocation'),
    $weatherTemp        = $('#jsTargetWeather'),
    $weatherIcon        = $('.weatherIcon'),
    $weatherDescription = $('#jsWeatherDescription'),
    $browser            = $('#jsTargetBrowser'),
    $system             = $('#jsTargetSystem');


function formatTemperature(kelvin) {
    var cels = (kelvin - 273.15).toFixed(1);
    $weatherTemp.html(cels);

}

function dataHandler(data) {
    var dataString = JSON.stringify(data);
    // console.log(data.main.temp);
    formatTemperature(data.main.temp);

    if (data.main.temp && data.sys) {
        // show icon
        if (data.weather) {
// picts list https://openweathermap.org/weather-conditions

// 01d.png 	01n.png 	clear sky
// 02d.png 	02n.png 	few clouds
// 03d.png 	03n.png 	scattered clouds
// 04d.png 	04n.png 	broken clouds
// 09d.png 	09n.png 	shower rain
// 10d.png 	10n.png 	rain
// 11d.png 	11n.png 	thunderstorm
// 13d.png 	13n.png 	snow
// 50d.png 	50n.png 	mist

            var imgURL = "/wp-content/themes/pivott/assets/img/svg/w_" + parseInt(data.weather[0].icon) + ".svg";
            $weatherIcon.attr("src", imgURL);
            $weatherDescription.html(data.weather[0].description);
        }
    }
}

function getWeather(locdata) {
    console.log(locdata)

    var apiURI = "https://api.openweathermap.org/data/2.5/weather?lat=" + locdata.latitude + "&lon=" + locdata.longitude + "&appid=5b58aee62c41eb64fcab16edce2e5cc1";
    
    if (locdata && locdata.city) {
        $location.html(locdata.city);
    } else if (locdata) {
        var latlng = new google.maps.LatLng(locdata.latitude, locdata.longitude);

        new google.maps.Geocoder().geocode({'latLng' : latlng}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    var country = null, countryCode = null, city = null, cityAlt = null;
                    var c, lc, component;
                    for (var r = 0, rl = results.length; r < rl; r += 1) {
                        var result = results[r];
        
                        if (!city && result.types[0] === 'locality') {
                            for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                                component = result.address_components[c];
        
                                if (component.types[0] === 'locality') {
                                    city = component.long_name;
                                    break;
                                }
                            }
                        }
                        else if (!city && !cityAlt && result.types[0] === 'administrative_area_level_1') {
                            for (c = 0, lc = result.address_components.length; c < lc; c += 1) {
                                component = result.address_components[c];
        
                                if (component.types[0] === 'administrative_area_level_1') {
                                    cityAlt = component.long_name;
                                    break;
                                }
                            }
                        } else if (!country && result.types[0] === 'country') {
                            country = result.address_components[0].long_name;
                            countryCode = result.address_components[0].short_name;
                        }
        
                        if (city && country) {
                            break;
                        }
                    }
        
                    $location.html(city)
                    // console.log("City: " + city + ", City2: " + cityAlt + ", Country: " + country + ", Country Code: " + countryCode);
                }
            }
        });
    } else {
        console.log("fail getWeather");
        // $location.html('a hidden place (location disabled)');
    }

    // return $.ajax({
    //     url: apiURI,
    //     dataType: "jsonp",
    //     type: "GET",
    //     async: "true",
    // }).done(dataHandler);
}

function getLocation() {
    function successFunction(position) {
        let loc = [];
            loc.latitude = position.coords.latitude;
            loc.longitude = position.coords.longitude;
        getWeather(loc)
    }
    function errorFunction(){
        console.log("Geocoder failed");
    }

    $.ajax({
        url: "https://ipapi.co/jsonp/",
        dataType: "jsonp",
        type: "GET",
        async: "true",
        success: function(data){
            getWeather(data)
        }
    });
}
var updateInterval = setInterval(getLocation(), 300000);
// var updateInterval = setInterval(getLocation().done(getWeather), 300000);

function showDateTime() {
    var now = new Date();
    // console.log(now);
    // $time.textContent = correctTime(now);
    $time.text(correctTime(now))
}
showDateTime();
setInterval(showDateTime, 1000);


// Correct time
function correctTime(time) {
    let h = time.getHours(),
        m = time.getMinutes(),
        s = time.getSeconds();
    return `${(h < 10 ? "0" : "") + h}:${(m < 10 ? "0" : "") + m}:${(s < 10 ? "0" : "") + s}`;
}


var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "an unknown version";
        this.OS = this.searchString(this.dataOS) || "an unknown OS";
    },
    searchString: function (data) {
        for (var i=0;i<data.length;i++) {
            var dataString = data[i].string;
            var dataProp = data[i].prop;
            this.versionSearchString = data[i].versionSearch || data[i].identity;
        if (dataString) {
            if (dataString.indexOf(data[i].subString) != -1)
                return data[i].identity;
        }
        else if (dataProp)
            return data[i].identity;
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index == -1) return;
        return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
    },
    dataBrowser: [
        {
            string: navigator.userAgent,
            subString: "Chrome",
            identity: "Chrome"
        },
        {
            string: navigator.userAgent,
            subString: "OmniWeb",
            versionSearch: "OmniWeb/",
            identity: "OmniWeb"
        },
        {
            string: navigator.vendor,
            subString: "Apple",
            identity: "Safari",
            versionSearch: "Version"
        },
        {
            prop: window.opera,
            identity: "Opera",
            versionSearch: "Version"
        },
        {
            string: navigator.vendor,
            subString: "iCab",
            identity: "iCab"
        },
        {
            string: navigator.vendor,
            subString: "KDE",
            identity: "Konqueror"
        },
        {
            string: navigator.userAgent,
            subString: "Firefox",
            identity: "Firefox"
        },
        {
            string: navigator.vendor,
            subString: "Camino",
            identity: "Camino"
        },
        {
            /* For Newer Netscapes (6+) */
            string: navigator.userAgent,
            subString: "Netscape",
            identity: "Netscape"
        },
        {
            string: navigator.userAgent,
            subString: "MSIE",
            identity: "Internet Explorer",
            versionSearch: "MSIE"
        },
        {
            string: navigator.userAgent,
            subString: "Gecko",
            identity: "Mozilla",
            versionSearch: "rv"
        },
        {
            /* For Older Netscapes (4-) */
            string: navigator.userAgent,
            subString: "Mozilla",
            identity: "Netscape",
            versionSearch: "Mozilla"
        }
    ],
    dataOS : [
        {
            string: navigator.platform,
            subString: "Win",
            identity: "Windows"
        },
        {
            string: navigator.platform,
            subString: "Mac",
            identity: "Mac"
        },
        {
            string: navigator.userAgent,
            subString: "iPhone",
            identity: "iPhone/iPod"
        },
        {
            string: navigator.platform,
            subString: "Linux",
            identity: "Linux"
        }
    ]

};
BrowserDetect.init();
$system.text(BrowserDetect.OS);
$browser.text(BrowserDetect.browser);


function setNightMode(){
	$('body').addClass('nightmode');
}
function NightMode(){
	let nowDate = new Date();
	let dst = 0;
	if(	// 28 is approx
		(nowDate.getMonth() > 3 && nowDate.getDate() > 28)
		|| (nowDate.getMonth() < 10 && nowDate.getDate() < 28)
	) {
		dst = 1;
	}

	let nowBelHours = nowDate.getUTCHours() + 1 + dst;

	if(nowBelHours < 6 || nowBelHours > 18){
		setNightMode();
	}
}
NightMode();
setInterval(NightMode, 3600000)