import $ from "jquery";
import "jquery.animate";
// import './components/jquery.fullpage.extensions.min';
import 'slick-carousel';
// import './components/pixi.js';
// import {TimelineMax, Power3, TweenMax} from 'gsap';
// import './components/sliderDisp.js';
import { TimelineMax, TweenMax, Power2, Power4 } from 'gsap';
import mixitup from 'mixitup';

import './components/parallaxScene';
import './components/userInfo';
import './components/form';

function redirectPage(linkLocation) {
    window.location = linkLocation;
}

if(document.querySelector('.main-page')){
    document.querySelector('.intro__video video').onloadeddata = function(){
        document.querySelector('.intro__video').classList.add('shown');
    }
}

// Плавное переключение страниц с учетом клилка по слайдерам
var isdragging = false;
$(function() {
    $('.layout').fadeIn(2000);

    $('.slick-track').on('dragstart',function(e){
        isdragging = true;
    });

    $('.slick-track').on('dragend',function(e){
        setTimeout(function(){isdragging = false}, 300);
    });
    
    $('a').on('click',function(e){
        if(!isdragging && $(e.target).attr('target') !== '_blank'){
            e.preventDefault();
            let linkLocation = e.currentTarget.href;
            $('body').fadeOut(1000, redirectPage(linkLocation));
        }
    });
})


// Change header background at scroll
window.onscroll = function(e) {
    if (window.pageYOffset > 50) {
        document.body.classList.add('scrolled');
    } else {
        document.body.classList.remove('scrolled');
    }
};


// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

let mobMenu = false;
if($(window).width() < 1024){
    mobMenu = true;
}

// menu animation example
// const nav = {
//     elem: {
//         items: $('.main-menu__body .menu-item'),
//     },
//     leave(){
//         const tl = new TimelineMax();
//         tl
//         .staggerFromTo(nav.elem.items, .4,
//             {
//                 opacity: 1
//             },
//             {
//                 opacity: 0
//             },
//             0.1
//         )

//         .fromTo(nav.elem.header, .4,
//             {
//                 opacity: 1
//             },
//             {
//                 opacity: 0
//             },
//         .4)
//     },
//     enter(){
//         const tle = new TimelineMax();
//         tle
//         .fromTo(nav.elem.wrapper, .4,
//             {
//                 transformOrigin: "50% 100%",
//                 scaleY: 0,
//                 opacity: 0
//             },
//             {
//                 scaleY: 1,
//                 opacity: 1
//             }
//         ,)
//     }
// }

//TL
var tlOpen = new TimelineMax({paused: true});
var tlClose = new TimelineMax({paused: true});
var lMain = $('.layout__main');
var menuTop = $('.menu-bg.top');
var menuMiddle = $('.menu-bg.middle');
var menuBottom = $('.menu-bg.bottom');
var menu = $('.nav');
var nav = {
    elem: {
        items: $('.main-menu__body .nav__list'),
    }
}

// tlOpen.add("preOpen")
//     .to(lMain, 0.4, {
//         scale: 0.8,
//         opacity: 0,
//         ease: Power2.easeOut
//     }, "preOpen")
//     .add("open", "-=0.4")
//     .to(menuTop, 0.8, {
//         x: "0",
//         ease: Power4.easeInOut
//     }, "open")
//     .to(menuMiddle, 0.8, {
//         x: "0",
//         ease: Power4.easeInOut
//     }, "open")
//     .to(menuBottom, 0.8, {
//         x: "0",
//         ease: Power4.easeInOut
//     }, "open")
//     .fromTo(nav.elem.items, 0.6, {
//         y: 30, opacity: 0, visibility: 'hidden'
//         }, {
//         y: 0, opacity: 1, visibility: 'visible', ease: Power4.easeOut
//     }, "-=0.2")
//     .add("preClose", "-=0.8")


$(document).on('click', '.main-menu__trigger', function(){
    $('body').toggleClass('menu-open');
    // nav.enter();
});
$(document).on('focus', '#search', function() {
    $('body').addClass('search-open');
});
$(document).on('blur', '#search', function() {
    $('body').removeClass('search-open');
})
// $(document).on('click', '.main-menu__wrapper .close-menu', function(){
//     nav.leave();
// });
// $(document).on('click', '.menu-item', function(){
//     nav.leave();
// });



// slider of news rangebar
var $rangeSliderCont = $('.slider__items');
if($('.top-slider').length){
    var rangeSlider = '';

function rangeSliderInit(){
    rangeSlider = $rangeSliderCont.slick({
        infinite: true,
        arrows: false,
        dots: true,
        autoplay: true,
        slidesToShow: 2,
        variableWidth: true,
        centerMode: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    });
}

    var result = [];
    function calculateItems(value) {
        var percent = value / 100;
        var length;
        if(percent < 0.5) {
            if(percent == 0) {
                percent = 1;
            }
            length = Math.round(advices.length * percent);
            // console.log(length)
            var inspirationLength = advices.length - length
            for(var i = 0; i < advices.length; i++) {
                if(i % 2 !== 0) {
                    if(i <= inspirationLength && inspirations[i]) {
                        result.push(inspirations[i])
                        continue;
                    }
                }
                result.push(advices[i]);
            }
            // result = result.concat(advices.slice(0, length));
            // result = result.concat(inspirations.slice(0, advices.length - length));
        } else {
            length = Math.round(inspirations.length * percent);
            // console.log(length)
            var advicesLength = inspirations.length - length;
            for(var k = 0; k < inspirations.length; k++) {
                if(k % 2 !== 0) {
                    if(k <= advicesLength && advices[k]) {
                        result.push(advices[k])
                        continue;
                    }
                }
                result.push(inspirations[k]);
            }
            // result = result.concat(inspirations.slice(0, length));
            // result = result.concat(advices.slice(0, inspirations.length - length));
        }

        result.forEach(function (item) {
            var item = `
        <article class="article ` + (item['post_type'] === 'inspiration' ? 'inspired' : 'advice') + `">
            <a href="` + item['link'] + `" class="article__wrapper">
                <div class="article__img"><img src="` + item['image'] + `" alt=""></div>
                <div class="article__block">
                    <div>
                        <h1>` + item['post_title'] + `</h1><span>` + item['tagNames'].join(' | ') + `</span>` + item['fields']['description']
                    + `</div>
                    <div class="more">Read more</div>
                </div>
            </a>
        </article>
        `;
            var documentElement = document.createElement("div");
                documentElement.className = "slider__item";
                documentElement.innerHTML = item;

            $rangeSliderCont.append(documentElement);
        });

        rangeSliderInit();
    }
    calculateItems(document.getElementById('range').value);

    // range change
    document.getElementById("range").addEventListener('change', function (e) {
        result = [];

        // remove old slider
        $rangeSliderCont.slick('unslick');
        $rangeSliderCont.html('');

        calculateItems(e.target.value);
    });
}



$(function() {
    const   awardSlider = $('.main-award__list.award .award__lists'),
            talentSlider = $('.talent__mobile-slider'),
            partnerSlider = $('.partners__row'),
            clientSlider = $('.clients__row'),
            $slider = $('.slick-slider'),
            mixitupEl = $('#mixitup');

    // function mobileSlider(classSlider) {
    //     classSlider.slick({
    //         cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    //         autoplay: true,
    //         infinite: true,
    //         arrows: false,
    //         dots: true,
    //         slidesToShow: 1,
    //         mobileFirst: true,
    //         responsive: [
    //             {
    //                 breakpoint: 767,
    //                 settings: 'unslick'
    //             }
    //         ]
    //     });
    // }

    // mobileSlider(awardSlider);
    // mobileSlider(talentSlider);
    // mobileSlider(partnerSlider);
    // mobileSlider(clientSlider);

    if ($slider.length) {
        var sliderCounter = document.createElement('div');
            sliderCounter.classList.add('slick-counter');

        // var updateSliderCounter = function(slick, currentIndex) {
        //     currentSlide = slick.slickCurrentSlide() + 1;
        // };

        // $slider.on('init', function(event, slick) {
        //     $slider.append(sliderCounter);
        //     updateSliderCounter(slick);
        // });

        // $slider.on('afterChange', function(event, slick, currentSlide) {
        //     updateSliderCounter(slick, currentSlide);
        // });

        $slider.not('.slick-initialized').slick({
            autoplay: true,
            infinite: true,
            arrows: false,
            dots: true,
            slidesToShow: 2,
            variableWidth: false,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        dots: false,
                        slidesToShow: 1,
                        centerPadding: '90px',
                    }
                },
            ]
        });
    }

    $('.slider-block').not('.slick-initialized').slick({
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        infinite: true,
        arrows: false,
        dots: true,
        mobileFirst: true,
        autoplay: true,
        centerMode: true,
        variableWidth: true,
        centerPadding: '90px',
        
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    centerMode: true,
                    centerPadding: '30px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '90px',
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '60px'
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3
                }
            },
            // {
            //     breakpoint: 2200,
            //     settings: {
            //         slidesToShow: 4,
            //         // variableWidth: true,
            //     }
            // }
        ]
    });


    // const filterByClassname = (classname) => {
    //     $('.slick-slider')
    //     .slick('slickUnfilter')
    //     .slick('slickFilter', $(`.${classname}`).closest('.slick-slide'));
    // };

    function rangeSlider() {
        const settings={
            fill: '#4AC0C3',
            background: '#AD6AD9'
        }
        const slider = $('#range');
        const sliderValueI = $('.rangeVal-i');
        const sliderValueA = $('.rangeVal-a');
        const sliderMax = slider.prop('max');
        const sliderMin = slider.prop('min');
        const sliderCurrent = slider.val();

        sliderValueI.html(`${sliderCurrent}% <i></i>`);
        sliderValueA.html(`<i></i> ${sliderMax - sliderCurrent}%`);

        // function maxMinRange(val) {
        //     if (val > 50) {
        //         filterByClassname('inspired')

        //     } else {
        //         filterByClassname('advice')

        //     }
        // }

        slider.on('input', (event) => {
            const _this = $(event.currentTarget);
            const _thisVal = _this.val();

            sliderValueI.html(`<i></i> ${_thisVal}%`);
            sliderValueA.html(`${sliderMax - _thisVal}% <i></i>`);
            applyFill(_this);
            // maxMinRange(_thisVal);
            // circleRotation((180 / 100) * _thisVal)
        });

        slider.on('change', function(event, slick, currentSlide) {
            document.querySelector('#slider_anker').scrollIntoView({ 
                behavior: 'smooth' 
            });
        });

        function applyFill(slider) {
            var percentage = Math.round((slider.val() - sliderMin) * 100 / sliderMax);
            const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage+0.1}%)`;
            slider.css('background', bg);
        }

        // maxMinRange(sliderCurrent);
        applyFill(slider);
        // circleRotation((180 / 100) * sliderCurrent)
        $('.slick-slider').slick('slickUnfilter');
    }

    function dayNight() {
        var today = new Date(),
            timeBlock = $('.local-time__view');
        timeBlock.html(today.toLocaleTimeString());
    }

    function openDayNight() {
        const dayNightTrigger = $('.local-time');

        dayNightTrigger.on('click', function() {
            dayNightTrigger.toggleClass('local-time--open');
        });
    }

    if (mixitupEl.length > 0) {
        var mixer = mixitup(mixitupEl, {
            animation: {
                effects: 'fade blur'
            },
            controls: {
                toggleLogic: 'or'
            },
            classNames: {
                block: 'filter',
                delineatorElement: '__',
                delineatorModifier: '--'
            },
        });
    }
    
    rangeSlider();
    dayNight();
    openDayNight();

    $('button.filter__mobile').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        $(e.currentTarget).closest('.filter__tabLists').toggleClass('is-show');
    });

    $('button.filter__control:not(.filter__mobile)').on('click', (e) => {
        $(e.currentTarget).closest('.filter__tabLists').find('button.filter__mobile').text($(e.currentTarget).text());
        $(e.currentTarget).closest('.filter__tabLists').removeClass('is-show');
    });
});

// Careers page, job page slider
$('#slider_careers').slick({
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    // autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
});

// Careers page truncate text
if(document.querySelector('.layout__main').classList.contains('careers-page')){
    const carSliderWrap     = document.getElementsByClassName("slider-block__wrap"),
          carSliderWrapOpen = document.getElementsByClassName("open");
    for (let i = 0; carSliderWrap.length > i; i++) {
      carSliderWrap[i].onclick = function() {
        let currentActive = carSliderWrapOpen[0];
        if (currentActive){
            currentActive.classList.remove("open");
        }
        if (currentActive !== this){
            this.classList.add("open");
        }
      };
    }
}