const cbx = document.querySelectorAll('.contact-form__input--checkbox'),
      btn = document.querySelectorAll('.contact-form__btn');
const name_ = document.querySelector('#name');
const email = document.querySelector('#email');
const message = document.querySelector('#message');

if(name_){
    name_.addEventListener('blur', () => {
        checkFields()
    })
} else {
    console.log('Cant find form :(')
}
if(email){
    email.addEventListener('blur', () => {
        checkFields()
    })
}
if(message){
    message.addEventListener('blur', () => {
        checkFields()
    })
}

cbx.forEach(item => {
    item.addEventListener('click', () => {
        item.classList.toggle('checked');
        checkFields();
    });
});

function checkFields() {
    if(name_.value === '' || email.value === '' || message.value === '' || !cbx[0].classList.contains('checked')) {
        btn[0].setAttribute('disabled', 'disabled');
    } else {
        btn[0].removeAttribute('disabled');
    }
}