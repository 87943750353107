import Parallax from 'parallax-js';

if (document.body.classList.contains('parallax-js')) {
    const scene = document.querySelectorAll('.parallax-scene');

    if (window.innerWidth >= 1199 ) {
        scene.forEach((item) => {
            item.querySelectorAll('.cross').forEach(
                (cross) => {cross.remove()}
            )

            item.querySelectorAll('svg').forEach(
                (svgimg) => {
                    if(svgimg.getAttribute('width') > 100){
                        svgimg.setAttribute('width', '100')
                    }
                    // console.log(svgimg.getAttribute('width'))
                }
            )

            const parallaxInstance = new Parallax(item, {
                relativeInput: true,
            });
        });
    } else {
        scene.forEach((item) => {
            item.remove();
        });
    }
}